const axios = require('axios').default;
const jQuery = require('jquery');

require('popper.js');
require('bootstrap');

import * as THREE from 'three';

import Stats from 'stats-js';

let camera; let scene; let renderer; let stats; let root;

let mouseX = 0; let mouseY = 0;

let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;

init();
animate();

function init() {
  camera = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 1, 15000 );
  camera.position.z = 500;

  scene = new THREE.Scene();
  scene.background = new THREE.Color( 0xffffff );

  const geometry = new THREE.BoxBufferGeometry( 100, 100, 100 );
  const material = new THREE.MeshNormalMaterial();

  root = new THREE.Mesh( geometry, material );
  root.position.x = 1000;
  scene.add( root );

  const amount = 200; let object; let parent = root;

  for ( var i = 0; i < amount; i ++ ) {
    object = new THREE.Mesh( geometry, material );
    object.position.x = 100;

    parent.add( object );
    parent = object;
  }

  parent = root;

  for ( var i = 0; i < amount; i ++ ) {
    object = new THREE.Mesh( geometry, material );
    object.position.x = - 100;

    parent.add( object );
    parent = object;
  }

  parent = root;

  for ( var i = 0; i < amount; i ++ ) {
    object = new THREE.Mesh( geometry, material );
    object.position.y = - 100;

    parent.add( object );
    parent = object;
  }

  parent = root;

  for ( var i = 0; i < amount; i ++ ) {
    object = new THREE.Mesh( geometry, material );
    object.position.y = 100;

    parent.add( object );
    parent = object;
  }

  parent = root;

  for ( var i = 0; i < amount; i ++ ) {
    object = new THREE.Mesh( geometry, material );
    object.position.z = - 100;

    parent.add( object );
    parent = object;
  }

  parent = root;

  for ( var i = 0; i < amount; i ++ ) {
    object = new THREE.Mesh( geometry, material );
    object.position.z = 100;

    parent.add( object );
    parent = object;
  }

  //

  renderer = new THREE.WebGLRenderer( {antialias: true} );
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight );
  document.body.appendChild( renderer.domElement );

  //

  // stats = new Stats();
  // document.body.appendChild( stats.dom );

  //

  document.addEventListener( 'mousemove', onDocumentMouseMove, false );

  //

  window.addEventListener( 'resize', onWindowResize, false );
}

function onWindowResize() {
  windowHalfX = window.innerWidth / 2;
  windowHalfY = window.innerHeight / 2;

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );
}

function onDocumentMouseMove( event ) {
  mouseX = ( event.clientX - windowHalfX ) * 10;
  mouseY = ( event.clientY - windowHalfY ) * 10;
}

//

function animate() {
  requestAnimationFrame( animate );

  render();
  // stats.update();
}

function render() {
  const time = Date.now() * 0.001 + 10000;

  const rx = Math.sin( time * 0.7 ) * 0.2;
  const ry = Math.sin( time * 0.3 ) * 0.1;
  const rz = Math.sin( time * 0.2 ) * 0.1;

  camera.position.x += ( mouseX - camera.position.x ) * 0.05;
  camera.position.y += ( - mouseY - camera.position.y ) * 0.05;

  camera.lookAt( scene.position );

  root.traverse( function( object ) {
    object.rotation.x = rx;
    object.rotation.y = ry;
    object.rotation.z = rz;
  } );

  renderer.render( scene, camera );
}

window.addEventListener('DOMContentLoaded', function() {

});
